import "./../App.css";
import React, { useState } from "react";
import { useRef } from "react";
import emailjs from "@emailjs/browser";
import { json } from "react-router-dom";

// const FormContacto = ({ estilo }) => {
//   const formData = {
//     nombre: "",
//     apellido: "",
//     email: "",
//     telefono: "",
//     direccion: "",
//     asunto: "",
//     mensaje: "",
//   };

//   let addEstiloForm;
//   if (estilo === "blanco") {
//     addEstiloForm = "_blanco";
//   } else {
//     addEstiloForm = "_negro";
//   }

//   const [formValue, setFormValue] = useState(formData);
//   const [formErrors, setFormErrors] = useState({});

//   function handleChange(event) {
//     const input = event.target;
//     const thisCampo = input.id;
//     const prevFormData = { ...formValue };
//     prevFormData[thisCampo] = input.value;
//     setFormValue(prevFormData);
//   }

//   function validateForm() {
//     const telefonoPattern = /^[0-9+#-]*$/;
//     const errors = {};

//     if (!formValue.telefono.match(telefonoPattern)) {
//       errors.telefono = "El teléfono solo puede contener números y símbolos especiales";
//     }

//     return errors;
//   }

//   function sendEmail(event) {
//     event.preventDefault();

//     const errors = validateForm();

//     if (Object.keys(errors).length === 0) {
//       emailjs
//         .sendForm(
//           process.env.REACT_APP_EMAILJS_SERVICE,
//           process.env.REACT_APP_EMAILJS_TEMPLATE,
//           event.target,
//           process.env.REACT_APP_EMAILJS_PUBLICKEY
//         )
//         .then(
//           (result) => {
//             console.log(result.text);
//             // Restablecer los valores de los inputs a ""
//             const formInputs = event.target.elements;
//             for (let i = 0; i < formInputs.length; i++) {
//               if (
//                 formInputs[i].nodeName === "INPUT" ||
//                 formInputs[i].nodeName === "TEXTAREA"
//               ) {
//                 formInputs[i].value = "";
//               }
//             }
//           },
//           (error) => {
//             console.log(error.text);
//           }
//         );

//       setFormValue(formData);
//     } else {
//       console.log(errors);
//       setFormErrors(errors);
//     }
//   }

//   return (
//     <div className={"containerContactForm" + addEstiloForm}>
//       <div className="contentContactForm">
//         <div className="left-content">
//           <div className={"h1Form" + addEstiloForm}>
//             <h1>Pedí tu presupuesto</h1>
//           </div>
//           <div className={"cuerpoForm" + addEstiloForm}>
//             <h2>
//               Llena el formulario o llamanos para una consulta a domicilio.
//             </h2>
//             <p>
//               <strong>Zonas de Servicio:</strong>
//             </p>
//             <p>Salta, Argentina.</p>
//             <p>Autopista CV Oeste - Paseo Comercial "El Sitio" - San Lorenzo Chico, 4400 - Salta, Argentina</p>
//             <p>ventas@modelica.com.ar</p>
//             <p>+54 9 387 223-8908</p>
//           </div>
//         </div>

//         <div className="right-content">
//           <form onSubmit={sendEmail}>
//             <div className="form-row">
//               <div className="form-group">
//                 <label className="formLabels" htmlFor="nombre">
//                   Nombre
//                 </label>
//                 <input
//                   className={"inputForm" + addEstiloForm}
//                   type="text"
//                   id="nombre"
//                   name="nombre"
//                   onChange={handleChange}
//                   required
//                 />
//               </div>

//               <div className="form-group">
//                 <label className="formLabels" htmlFor="apellido">
//                   Apellido
//                 </label>
//                 <input
//                   className={"inputForm" + addEstiloForm}
//                   type="text"
//                   id="apellido"
//                   name="apellido"
//                   onChange={handleChange}
//                   required
//                 />
//               </div>
//             </div>

//             <label className="formLabels" htmlFor="email">
//               Email
//             </label>
//             <input
//               className={"inputForm" + addEstiloForm}
//               type="email"
//               id="email"
//               name="email"
//               onChange={handleChange}
//               required
//             />

//             <label className="formLabels" htmlFor="telefono">
//               Teléfono
//             </label>
//             <input
//               className={"inputForm" + addEstiloForm}
//               type="tel"
//               id="telefono"
//               name="telefono"
//               onChange={handleChange}
//               pattern="[0-9+#-]*"
//               required
//             />
//             {formErrors.telefono && (
//               <span className="error-message">{formErrors.telefono}</span>
//             )}

//             <label className="formLabels" htmlFor="direccion">
//               Dirección
//             </label>
//             <input
//               className={"inputForm" + addEstiloForm}
//               type="text"
//               id="direccion"
//               name="direccion"
//               onChange={handleChange}
//             />

//             <label className="formLabels" htmlFor="asunto">
//               Asunto
//             </label>
//             <input
//               className={"inputForm" + addEstiloForm}
//               type="text"
//               id="asunto"
//               name="asunto"
//               onChange={handleChange}
//             />

//             <label className="formLabels" htmlFor="mensaje">
//               Escribe tu mensaje aquí...
//             </label>
//             <textarea
//               className={"formTextArea" + addEstiloForm}
//               id="mensaje"
//               name="mensaje"
//               rows="4"
//               onChange={handleChange}
//             ></textarea>

//             <button
//               className={"formButtonSubmit" + addEstiloForm}
//               type="submit"
//             >
//               Enviar
//             </button>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default FormContacto;


const ContactUs = () => {
  const form = useRef();
  const [emailSent, setEmailSent] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_b59nypm', 'template_9a602ya', form.current, 'UsP3scySiipuFGoh0')
    .then((result) => {
      console.log(result.text);
      console.log("message sent");
      setEmailSent(true);
      form.current.reset(); 
  }, (error) => {
      console.log(error.text);
  });
  };

  return (
    
    <div className="containerContactForm_negro" > 
    <div className="contentContactForm">
    <div className="left-content">
      <div className="h1Form_negro">
        <h1>Pedí tu presupuesto</h1>
      </div>
      <div className="cuerpoForm_negro">
      <h2>Llena el formulario o llamanos para una consulta a domicilio.</h2>
      <p><strong>Zonas de Servicio:</strong></p>
      <p>Salta, Argentina.</p>
      <p>Autopista CV Oeste - Paseo Comercial "El Sitio" - San Lorenzo Chico, 4400 - Salta, Argentina</p>
      <p>ventas@modelica.com.ar</p>
      <p>+54 9 387 223-8908</p>
      </div>
    </div>
    
    <div className="right-content">
    {emailSent && <p className='p-enviado'>Mensaje enviado.</p>}
    <form ref={form} onSubmit={sendEmail}>
      <label className="formLabels">Nombre</label>
      <input type="text" name="user_name" class="inputForm_negro"/>
      <label className="formLabels">Email</label>
      <input type="email" name="user_email" class="inputForm_negro"/>
      <label className="formLabels">Dirección</label>
      <input type="text" name="user_direc" class="inputForm_negro"/>
      <label className="formLabels">Teléfono</label>
      <input type="text" name="user_cel" class="inputForm_negro"/>
      <label className="formLabels">Mensaje</label>
      <textarea name="message" className="formTextArea_negro"/>
      <input type="submit" value="Send" className="formButtonSubmit_negro"/>
    </form>
    </div>
    
    
    </div>
    </div> 
  );
};


export default ContactUs;